import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.div
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onKeyUpFunction
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.get

external fun callMain(argument: Array<out String>)

fun ybcon(vararg argument: String) = callMain(argument)

val console get() = document.body!!.children["console"] as HTMLDivElement

private var typingTimer: Int = 0
const val typing_interval = 500
fun update_output_box(text: String) {
    window.clearTimeout(typingTimer)
    ybcon("--target=${(console.children["output_language"] as HTMLSelectElement).value}", "-t", text)
    typingTimer = window.setTimeout({
        (console.children["output_box"] as HTMLTextAreaElement).value = js("yerbacon_output") as String
    }, typing_interval)
}

const val bright = "white"
const val dark = "#323330"

@JsExport
fun makeConsole() {
    with(document.body!!.append) {
        div {
            id = "console"
            style { unsafe { raw("""
                #console {
                    position: relative;
                    display: none; filter: blur(0px);
                    transition: all 0.25s ease-in;
                }
                #console:hover { box-shadow: 0 0 50px #2f5361; }
                #console, #input_box { border-top-left-radius: 10px; }
                #console, #output_box { border-top-right-radius: 10px; }
                #console, #output_language { border-bottom-left-radius: 10px; }
                #console, #output_language { border-bottom-right-radius: 10px; }
                textArea, #output_language {
                    color: $bright;
                    border-color: #2f5361;
                    outline: none !important;
                    background: #2f5361;
                    font-family: "Noto Sans Mono", monospace;
                    width: 25vw; height: 50vh;
                    resize: none;
                }
                #input_box, #output_box { border: solid 1px #2f5361; }
                #input_box { border-right-color: #173f4f; }
                #output_box { border-left-color: #173f4f; }
                #output_language {
                    background-image: linear-gradient(30deg, #02027d 0%, #2f5361 20%);
                    position: absolute;
                    top: 100%; left: 0%;
                    width: 100%; height: auto;
                }
                """.trimIndent()
            ) } }
            script(src = "/ybcon.js") {}
            textArea {
                id = "input_box"
                onKeyUpFunction = { event ->
                    update_output_box((event.target as HTMLTextAreaElement).value)
                }
            }
            textArea {
                readonly = true
                id = "output_box"
            }
            select {
                id = "output_language"
                val languagesMap = mapOf(
                    ("Lua" to "lua") to ("#02027d 0%" to bright),
                    ("Javascript" to "js") to ("#f0db4f 0%" to dark),
                    ("Python" to "py") to ("#3776ab 8%, #ffd343 12%" to bright),
                    ("GDScript" to "gd") to ("#478cbf 0%" to bright)
                )
                languagesMap.forEach { pair ->
                    option {
                        value = pair.key.second
                        label = pair.key.first
                    }
                }
                onChangeFunction = { event ->
                    val select = (event.target as HTMLSelectElement)
                    val currentLanguage = languagesMap.entries.find { it.key.second == select.value}!!
                    select.style.color = currentLanguage.value.second
                    select.style.backgroundImage = "linear-gradient(30deg, ${currentLanguage.value.first}, #2f5361 20%)"
                    val text = (console.children["input_box"] as HTMLTextAreaElement).value
                    if (text.isNotEmpty()) {
                        update_output_box(text)
                    }
                }
            }
        }
        div {
            style { unsafe {
                raw("""
                button {
                    position: fixed;
                    bottom: 10px;
                    right: 10px;
                    border: none;
                    background: inherit;
                }
                button:hover {
                    background: #2f5361;
                }
                """.trimIndent())
            } }
            button {
                + "Yerbacon programming language project"
                onClickFunction = {
                    with(console.style) {
                        display = when (display) {
                            "none", "" -> "inline-block"
                            else -> "none"
                        }
                    }
                }
            }
        }
    }
}

fun main() {
    if (window.closed) window.open("index.html")
}
